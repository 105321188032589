.fullName-screen__wrapper {
    height: calc(100% - 137px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
}

.phone__container .fullName__checkbox.is-selected {
    background: var(--primary-color);
}

.phoneContinue__btn.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.ri-arrow-right-line {
    font-size: 25px;
    padding-left: 5px;
}

.phone__actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

@media (hover: hover) {
    .phoneContinue__btn:hover {
        background: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
}

.phone__wrappers {
    padding: 32px;
    flex: 1;
    height: calc(100% - 137px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
}

.phone--element .phone__wrapper--input {
    position: relative;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    border-radius: 25px;
    /* background-color: #fff; */
}

div.phone--element {
    border: 1px solid lightgrey;
    border-radius: 8px;
    display: block;
    position: relative;
    width: 100%;
    background-color: #fff;
    border-color: lightgrey;
}

div.phone--element.old-phone {
    border-radius: 30px;
    background-color: #ddd;
}

.valid {
    border-color: var(--primary-color) !important;
}

div.phone--element.valid label {
    top: -8px !important;
    left: 21px !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    font-size: 12px !important;
}

div.phone.invalid label {
    top: -8px !important;
    left: 21px !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    font-size: 12px !important;
}

div.phone--element.valid input {
    background-color: #fff;
}

div.phone--element.invalid input {
    background-color: #fff;
}

div.phone--element.old-phone.invalid input {
    background-color: #ddd;
}

div.phone--element.old-phone.invalid input:focus {
    background-color: #fff;
}

.phone__container input {
    box-sizing: border-box;
    width: 60%;
    min-height: 56px;
    border-radius: 30px;
    border: none;
    padding-left: 10px;
    padding-right: 0px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.phone__container input::placeholder {
    color: #ddd;
}

.phone--element.old-phone input::placeholder {
    color: #fff;
}

.phone--element.old-phone input:focus::placeholder {
    color: #ddd;
}

.phone__container input:focus {
    outline: none !important;
    background-color: #fff;
    color: var(--primary-color);
}

.phone__container textarea {
    padding: 1rem 1rem;
    min-height: 110px;
}

.phone__container label {
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 22px;
    -webkit-transition: top .2s, left .2s, font-size .2s, -webkit-transform .2s;
    font-size: 14px;
    z-index: 5;
    pointer-events: none;
    color: #888;
    padding-left: 3px;
}

div.phone--element.invalid label:before {
    display: block !important;
}

div.phone--element.valid label::before {
    display: block !important;
}

.phone__container label::before {
    top: 0%;
    height: 56%;
    background-color: #f4f4f4;
    width: 105%;
    content: "";
    display: none;
    position: absolute;
    left: 0;
    z-index: -1;
}

.phone--element input:focus+.phone__label:after,
.phone--element input:focus+.phone__label:before {
    display: block;
}

.phone--element input:focus+.phone__label {
    top: -8px;
    left: 21px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-size: 12px;
}

.phone--element .valid {
    border-color: var(--primary-color);
}

.valid input {
    color: var(--primary-color) !important;
    padding-right: calc(1.5em + 0.75rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important; */
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.hidden {
    display: none;
}

.invalid input {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important; */
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid input::placeholder {
    color: #d46d77 !important;
}

.phone__valid {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    background-color: var(--primary-color);
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
}

.phone__invalid {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    background-color: #fb5d5d;
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
}

.ri-close-line {
    font-weight: 900;
    font-size: 21px;
}

.phone--element .phone__countryCode {
    position: relative;
    left: 5px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    min-width: 72px;
    font-size: small;
    height: 38px;
    background-color: #fff;
}

.phone--element.old-phone .phone__countryCode {
    border-radius: 30px;
}

.phone--element .phone__countryCode--dropdown {
    position: absolute;
    border: 1px solid lightgrey;
    top: 33px;
    left: -1px;
    width: 103%;
    height: 160px;
    border-radius: 0 0 10px 10px;
    background: white;
    overflow: auto;
    z-index: 10;
    scrollbar-width: none;
    /* Firefox */
}

.phone--element.old-phone .phone__countryCode--dropdown {
    border-radius: 0 0 20px 20px;
}

.phone--element .phone__countryCode--dropdown::-webkit-scrollbar {
    display: none;
}

.phone--element .phone__countryCode--select {
    padding: 0 !important;
    margin: 0;
}

.phone--element .phone__countryCode--option {
    list-style: none;
    padding: 0.5rem;
    display: flex;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}

.phone--element .phone__countryCode--option:hover {
    background: #ededed;
}

.phone__wrapper--input .flag-icon {
    width: 20px;
    height: 20px;
}

.phone__wrapper--input .open {
    border-radius: 8px 8px 0 0 !important;
}

.phone--element.old-phone .phone__wrapper--input .open {
    border-radius: 20px 20px 0 0 !important;
}

.phone--input {
    background-image: none !important;
}

.phone--input.old-phone-input {
    background-color: #ddd;
}

.phone--element.old-phone input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.phone--element.old-phone input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ddd inset !important;
}

.phone--element .ri-close-line {
    font-weight: 600;
    font-size: 25px;
}

.phone--element .ri-check-line {
    font-size: 25px;
    font-weight: 600;
}

.screen__content.screen--phone:focus-visible {
    outline: none;
}

.phoneContinue__btn {
    border-radius: 10px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-color);
    border: 1px solid transparent;
    width: 40%;
    border-radius: 25px;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.phoneContinue__btn span:first-child {
    text-align: center;
}

.phoneContinue__btn.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.phoneContinue__btn .loading__wrapper {
    pointer-events: none;
    opacity: 0.5;
    background-color: white;
    position: absolute;
    width: calc(100% + 5px);
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    height: 50px;
    border-radius: 25px;
    top: -2px;
}

.phoneContinue__btn .loading__wrapper.loading__widget {
    right: -100%;
    transition: right .4s;
    height: 100%;
    background: transparent;
    flex-direction: column;
}

.phoneContinue__btn .loading__wrapper .loader {
    height: 100%;
    display: flex;
    width: 100%;
    background: #fff;
    border-radius: 0 0 10px 0;
    border-radius: 25px;
    justify-content: center;
}

.phoneContinue__btn .loading__wrapper svg {
    width: 75px;
    height: 35px;
}

.phoneContinue__btn .loading__wrapper svg rect {
    fill: #fff;
}

.phoneContinue__btn .loading__iframe {
    opacity: 1 !important;
}


.phoneContinue__btn.disabled--noneOpacity {
    pointer-events: none;
}

.phone--title {
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 8px;
}

.phone--sub-title {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #000;
    opacity: 0.8;
}

.phone--title-wrapper {
    margin-bottom: 30px;
}

.phone__input-wrapper {
    width: 100%;
    display: flex;
    justify-content: start;
}

.phone__input-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-bottom: 8px;
}

.phone--title-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.phone__wrappers .card {
    padding-left: 15px;
    padding-right: 15px;
}

.form__notice {
    display: block;
    text-align: center;
    font-size: 8px;
    color: #999;
    margin-top: 20px;
}

.desktop-ver .phone__container.screen {
    flex-direction: row;
}