.validateDigits--title-wrapper {
    margin-bottom: 20px;
}

.validateDigits--title {
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 8px;
}

.validateDigits--title-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.validateDigits--title-icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(15, 64, 96, 0.06);
    border-radius: 50%;
    padding: 12px;
}

.validateDigits--sub-title {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #000;
    opacity: 0.8;
    margin-bottom: 6px;
}

.validateDigits__card {
    background: white;
    border-radius: 6px;
    padding: 32px 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.validateDigits__container .fullName__checkbox.is-selected {
    background: var(--primary-color);
}

.validateDigitsContinue__btn.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.ri-arrow-right-line {
    font-size: 25px;
    padding-left: 5px;
}

.validateDigits__actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

@media (hover: hover) {
    .validateDigitsContinue__btn:hover {
        background: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
}

.validateDigits__wrappers {
    padding: 32px;
    flex: 1;
    height: calc(100% - 137px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
}

.validateDigits--element .validateDigits__wrapper--input {
    display: flex !important;
    justify-content: space-around;
}

.validateDigits--element .validateDigits__wrapper--input .validateDigits__wrapper--input-field {
    border-radius: 6px;
    border: 1px solid lightgrey;
    border-color: var(--primary-color);
    width: 20% !important;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center
}

div.validateDigits--element {
    /* border: 1px solid lightgrey; */
    /* border-radius: 30px; */
    display: block;
    position: relative;
    width: 100%;
    /* background-color: #fff; */
    /* border-color: var(--primary-color); */
}

.valid {
    border-color: var(--primary-color) !important;
}

div.validateDigits--element.valid label {
    top: -8px !important;
    left: 21px !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    font-size: 12px !important;
}

div.validateDigits.invalid label {
    top: -8px !important;
    left: 21px !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    font-size: 12px !important;
}

div.validateDigits--element.valid input {
    background-color: #fff;
}

div.validateDigits--element.invalid input {
    background-color: #fff;
}


div.validateDigits--element.on-focus {
    border: 1px solid var(--primary-color) !important;
}

.validateDigits__container input {
    box-sizing: border-box;
    width: 100%;
    min-height: 56px;
    border-radius: 30px;
    border: none;
    padding: 0 15px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.validateDigits__container input::placeholder {
    color: #ddd;
}

.validateDigits__container input:focus {
    outline: none !important;
    background-color: #fff;
    color: var(--primary-color);
}

.validateDigits__container textarea {
    padding: 1rem 1rem;
    min-height: 110px;
}

.validateDigits__container label {
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 22px;
    -webkit-transition: top .2s, left .2s, font-size .2s, -webkit-transform .2s;
    font-size: 14px;
    z-index: 5;
    pointer-events: none;
    color: #888;
    padding-left: 3px;
}

div.validateDigits--element.invalid label:before {
    display: block !important;
}

div.validateDigits--element.valid label::before {
    display: block !important;
}

.validateDigits__container label::before {
    top: 0%;
    height: 56%;
    background-color: #f4f4f4;
    width: 105%;
    content: "";
    display: none;
    position: absolute;
    left: 0;
    z-index: -1;
}

.validateDigits--element input:focus+.validateDigits__label:after,
.validateDigits--element input:focus+.validateDigits__label:before {
    display: block;
}

.validateDigits--element input:focus+.validateDigits__label {
    top: -8px;
    left: 21px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-size: 12px;
}

.validateDigits--element .valid {
    border-color: var(--primary-color);
}

.valid input {
    color: var(--primary-color) !important;
    padding-right: calc(1.5em + 0.75rem);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important; */
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.hidden {
    display: none;
}

.invalid input {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important; */
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid input::placeholder {
    color: #d46d77 !important;
}

.validateDigits__valid {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    color: #fff;
    background-color: var(--primary-color);
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
}

.validateDigits__invalid {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    color: #fff;
    background-color: #fb5d5d;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
}

.ri-close-line {
    font-weight: 900;
    font-size: 21px;
}

.validateDigits--input {
    background-image: none !important;
}


.validateDigits--element .ri-close-line {
    font-weight: 600;
    font-size: 25px;
}


.validateDigits--element .ri-check-line {
    font-size: 25px;
    font-weight: 600;
}

.screen__content.screen--validateDigits:focus-visible {
    outline: none;
}

.validateDigitsContinue__btn {
    border-radius: 10px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-color);
    border: 1px solid transparent;
    width: 40%;
    border-radius: 25px;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.validateDigitsContinue__btn .loading__wrapper {
    pointer-events: none;
    opacity: 0.5;
    background-color: white;
    position: absolute;
    width: calc(100% + 5px);
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    height: 50px;
    border-radius: 25px;
    top: -2px;
}

.validateDigitsContinue__btn .loading__wrapper.loading__widget {
    right: -100%;
    transition: right .4s;
    height: 100%;
    background: transparent;
    flex-direction: column;
}

.validateDigitsContinue__btn .loading__wrapper .loader {
    height: 100%;
    display: flex;
    width: 100%;
    background: #fff;
    border-radius: 0 0 10px 0;
    border-radius: 25px;
    justify-content: center;
}

.validateDigitsContinue__btn .loading__wrapper svg {
    width: 75px;
    height: 35px;
}

.validateDigitsContinue__btn .loading__wrapper svg rect {
    fill: #fff;
}

.validateDigitsContinue__btn .loading__iframe {
    opacity: 1 !important;
}


.validateDigitsContinue__btn.disabled--noneOpacity {
    pointer-events: none;
}

.validateDigits--resend-otp {
    background-color: white;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    color: black;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.validateDigits--resend-otp--disabled {
    pointer-events: none;
    opacity: 0.5;
}

.validateDigits--resend-otp button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
}

.validateDigits--resend-otp button svg {
    height: 15px;
    width: 15px;
    margin-right: 4px;
}

.validateDigits__resend-timer {
    min-width: 40px;
}

.desktop-ver .validateDigits__container.screen {
    flex-direction: row;
}