@import "https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Poppins:wght@300;400;500;600&display=swap";
.fullName-screen__wrapper {
  height: calc(100% - 137px);
  background: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
}

.phone__container .fullName__checkbox.is-selected {
  background: var(--primary-color);
}

.phoneContinue__btn.disabled {
  opacity: .5;
  pointer-events: none;
}

.ri-arrow-right-line {
  padding-left: 5px;
  font-size: 25px;
}

.phone__actions {
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

@media (hover: hover) {
  .phoneContinue__btn:hover {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background: #fff;
  }
}

.phone__wrappers {
  height: calc(100% - 137px);
  background-color: #f4f4f4;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.phone--element .phone__wrapper--input {
  cursor: pointer;
  border-radius: 25px;
  flex-direction: row;
  align-items: center;
  position: relative;
  display: flex !important;
}

div.phone--element {
  width: 100%;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  display: block;
  position: relative;
}

div.phone--element.old-phone {
  background-color: #ddd;
  border-radius: 30px;
}

.valid {
  border-color: var(--primary-color) !important;
}

div.phone--element.valid label, div.phone.invalid label {
  font-size: 12px !important;
  top: -8px !important;
  left: 21px !important;
  transform: none !important;
}

div.phone--element.valid input, div.phone--element.invalid input {
  background-color: #fff;
}

div.phone--element.old-phone.invalid input {
  background-color: #ddd;
}

div.phone--element.old-phone.invalid input:focus {
  background-color: #fff;
}

.phone__container input {
  box-sizing: border-box;
  width: 60%;
  min-height: 56px;
  border: none;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 0;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.phone__container input::placeholder {
  color: #ddd;
}

.phone--element.old-phone input::placeholder {
  color: #fff;
}

.phone--element.old-phone input:focus::placeholder {
  color: #ddd;
}

.phone__container input:focus {
  color: var(--primary-color);
  background-color: #fff;
  outline: none !important;
}

.phone__container textarea {
  min-height: 110px;
  padding: 1rem;
}

.phone__container label {
  z-index: 5;
  pointer-events: none;
  color: #888;
  padding-left: 3px;
  font-size: 14px;
  -webkit-transition: top .2s, left .2s, font-size .2s, -webkit-transform .2s;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
}

div.phone--element.invalid label:before, div.phone--element.valid label:before {
  display: block !important;
}

.phone__container label:before {
  height: 56%;
  width: 105%;
  content: "";
  z-index: -1;
  background-color: #f4f4f4;
  display: none;
  position: absolute;
  top: 0%;
  left: 0;
}

.phone--element input:focus + .phone__label:after, .phone--element input:focus + .phone__label:before {
  display: block;
}

.phone--element input:focus + .phone__label {
  font-size: 12px;
  top: -8px;
  left: 21px;
  transform: none;
}

.phone--element .valid {
  border-color: var(--primary-color);
}

.valid input {
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
  color: var(--primary-color) !important;
}

.hidden {
  display: none;
}

.invalid input {
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.invalid input::placeholder {
  color: #d46d77 !important;
}

.phone__valid {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.phone__invalid {
  width: 30px;
  height: 30px;
  color: #fff;
  background-color: #fb5d5d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.ri-close-line {
  font-size: 21px;
  font-weight: 900;
}

.phone--element .phone__countryCode {
  min-width: 72px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  font-size: small;
  position: relative;
  left: 5px;
}

.phone--element.old-phone .phone__countryCode {
  border-radius: 30px;
}

.phone--element .phone__countryCode--dropdown {
  width: 103%;
  height: 160px;
  z-index: 10;
  scrollbar-width: none;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 33px;
  left: -1px;
  overflow: auto;
}

.phone--element.old-phone .phone__countryCode--dropdown {
  border-radius: 0 0 20px 20px;
}

.phone--element .phone__countryCode--dropdown::-webkit-scrollbar {
  display: none;
}

.phone--element .phone__countryCode--select {
  margin: 0;
  padding: 0 !important;
}

.phone--element .phone__countryCode--option {
  place-content: space-around flex-start;
  align-items: center;
  padding: .5rem;
  list-style: none;
  display: flex;
}

.phone--element .phone__countryCode--option:hover {
  background: #ededed;
}

.phone__wrapper--input .flag-icon {
  width: 20px;
  height: 20px;
}

.phone__wrapper--input .open {
  border-radius: 8px 8px 0 0 !important;
}

.phone--element.old-phone .phone__wrapper--input .open {
  border-radius: 20px 20px 0 0 !important;
}

.phone--input {
  background-image: none !important;
}

.phone--input.old-phone-input {
  background-color: #ddd;
}

.phone--element.old-phone input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

.phone--element.old-phone input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #ddd !important;
}

.phone--element .ri-close-line, .phone--element .ri-check-line {
  font-size: 25px;
  font-weight: 600;
}

.screen__content.screen--phone:focus-visible {
  outline: none;
}

.phoneContinue__btn {
  color: #fff;
  cursor: pointer;
  background: var(--primary-color);
  width: 40%;
  min-height: 3rem;
  border: 1px solid #0000;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: relative;
}

.phoneContinue__btn span:first-child {
  text-align: center;
}

.phoneContinue__btn.disabled {
  opacity: .5;
  pointer-events: none;
}

.phoneContinue__btn .loading__wrapper {
  pointer-events: none;
  opacity: .5;
  width: calc(100% + 5px);
  box-shadow: none;
  pointer-events: none;
  z-index: 10;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -2px;
}

.phoneContinue__btn .loading__wrapper.loading__widget {
  height: 100%;
  background: none;
  flex-direction: column;
  transition: right .4s;
  right: -100%;
}

.phoneContinue__btn .loading__wrapper .loader {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  justify-content: center;
  display: flex;
}

.phoneContinue__btn .loading__wrapper svg {
  width: 75px;
  height: 35px;
}

.phoneContinue__btn .loading__wrapper svg rect {
  fill: #fff;
}

.phoneContinue__btn .loading__iframe {
  opacity: 1 !important;
}

.phoneContinue__btn.disabled--noneOpacity {
  pointer-events: none;
}

.phone--title {
  text-align: center;
  color: #000;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
}

.phone--sub-title {
  text-align: center;
  color: #000;
  opacity: .8;
  font-size: 12px;
  font-weight: 400;
}

.phone--title-wrapper {
  margin-bottom: 30px;
}

.phone__input-wrapper {
  width: 100%;
  justify-content: start;
  display: flex;
}

.phone__input-label {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
}

.phone--title-icon {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.phone__wrappers .card {
  padding-left: 15px;
  padding-right: 15px;
}

.form__notice {
  text-align: center;
  color: #999;
  margin-top: 20px;
  font-size: 8px;
  display: block;
}

.desktop-ver .phone__container.screen {
  flex-direction: row;
}

.toast__container.error {
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  color: #fff;
  max-height: calc(100% - 200px);
  cursor: pointer;
  line-break: auto;
  background: #da5049;
  border-radius: 9px;
  padding: 2rem 4rem 2rem 2rem;
  font-size: 15px;
  transition: transform .4s;
  position: absolute;
  top: 2rem;
  transform: translate3d(150%, 0, 0);
}

.toast__container.error .error-msg {
  color: #fff;
  line-break: auto;
  font-size: 15px;
}

.toast__container.show {
  transform: translate3d(6%, 0, 0);
}

span.close-error {
  cursor: pointer;
  font-size: 27px;
  position: absolute;
  top: 29px;
  right: 20px;
}

.validateDigits--title-wrapper {
  margin-bottom: 20px;
}

.validateDigits--title {
  text-align: center;
  color: #000;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
}

.validateDigits--title-icon {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.validateDigits--title-icon-background {
  background-color: #0f40600f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
}

.validateDigits--sub-title {
  text-align: center;
  color: #000;
  opacity: .8;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
}

.validateDigits__card {
  background: #fff;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 29px;
  display: flex;
}

.validateDigits__container .fullName__checkbox.is-selected {
  background: var(--primary-color);
}

.validateDigitsContinue__btn.disabled {
  opacity: .5;
  pointer-events: none;
}

.ri-arrow-right-line {
  padding-left: 5px;
  font-size: 25px;
}

.validateDigits__actions {
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

@media (hover: hover) {
  .validateDigitsContinue__btn:hover {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background: #fff;
  }
}

.validateDigits__wrappers {
  height: calc(100% - 137px);
  background-color: #f4f4f4;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.validateDigits--element .validateDigits__wrapper--input {
  justify-content: space-around;
  display: flex !important;
}

.validateDigits--element .validateDigits__wrapper--input .validateDigits__wrapper--input-field {
  border: 1px solid #d3d3d3;
  border-color: var(--primary-color);
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  width: 20% !important;
}

div.validateDigits--element {
  width: 100%;
  display: block;
  position: relative;
}

.valid {
  border-color: var(--primary-color) !important;
}

div.validateDigits--element.valid label, div.validateDigits.invalid label {
  font-size: 12px !important;
  top: -8px !important;
  left: 21px !important;
  transform: none !important;
}

div.validateDigits--element.valid input, div.validateDigits--element.invalid input {
  background-color: #fff;
}

div.validateDigits--element.on-focus {
  border: 1px solid var(--primary-color) !important;
}

.validateDigits__container input {
  box-sizing: border-box;
  width: 100%;
  min-height: 56px;
  border: none;
  border-radius: 30px;
  padding: 0 15px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.validateDigits__container input::placeholder {
  color: #ddd;
}

.validateDigits__container input:focus {
  color: var(--primary-color);
  background-color: #fff;
  outline: none !important;
}

.validateDigits__container textarea {
  min-height: 110px;
  padding: 1rem;
}

.validateDigits__container label {
  z-index: 5;
  pointer-events: none;
  color: #888;
  padding-left: 3px;
  font-size: 14px;
  -webkit-transition: top .2s, left .2s, font-size .2s, -webkit-transform .2s;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
}

div.validateDigits--element.invalid label:before, div.validateDigits--element.valid label:before {
  display: block !important;
}

.validateDigits__container label:before {
  height: 56%;
  width: 105%;
  content: "";
  z-index: -1;
  background-color: #f4f4f4;
  display: none;
  position: absolute;
  top: 0%;
  left: 0;
}

.validateDigits--element input:focus + .validateDigits__label:after, .validateDigits--element input:focus + .validateDigits__label:before {
  display: block;
}

.validateDigits--element input:focus + .validateDigits__label {
  font-size: 12px;
  top: -8px;
  left: 21px;
  transform: none;
}

.validateDigits--element .valid {
  border-color: var(--primary-color);
}

.valid input {
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
  color: var(--primary-color) !important;
}

.hidden {
  display: none;
}

.invalid input {
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.invalid input::placeholder {
  color: #d46d77 !important;
}

.validateDigits__valid {
  width: 33px;
  height: 33px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.validateDigits__invalid {
  width: 33px;
  height: 33px;
  color: #fff;
  background-color: #fb5d5d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.ri-close-line {
  font-size: 21px;
  font-weight: 900;
}

.validateDigits--input {
  background-image: none !important;
}

.validateDigits--element .ri-close-line, .validateDigits--element .ri-check-line {
  font-size: 25px;
  font-weight: 600;
}

.screen__content.screen--validateDigits:focus-visible {
  outline: none;
}

.validateDigitsContinue__btn {
  color: #fff;
  cursor: pointer;
  background: var(--primary-color);
  width: 40%;
  min-height: 3rem;
  border: 1px solid #0000;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: relative;
}

.validateDigitsContinue__btn .loading__wrapper {
  pointer-events: none;
  opacity: .5;
  width: calc(100% + 5px);
  box-shadow: none;
  pointer-events: none;
  z-index: 10;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -2px;
}

.validateDigitsContinue__btn .loading__wrapper.loading__widget {
  height: 100%;
  background: none;
  flex-direction: column;
  transition: right .4s;
  right: -100%;
}

.validateDigitsContinue__btn .loading__wrapper .loader {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  justify-content: center;
  display: flex;
}

.validateDigitsContinue__btn .loading__wrapper svg {
  width: 75px;
  height: 35px;
}

.validateDigitsContinue__btn .loading__wrapper svg rect {
  fill: #fff;
}

.validateDigitsContinue__btn .loading__iframe {
  opacity: 1 !important;
}

.validateDigitsContinue__btn.disabled--noneOpacity {
  pointer-events: none;
}

.validateDigits--resend-otp {
  color: #000;
  opacity: 1;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.validateDigits--resend-otp--disabled {
  pointer-events: none;
  opacity: .5;
}

.validateDigits--resend-otp button {
  background-color: #0000;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.validateDigits--resend-otp button svg {
  height: 15px;
  width: 15px;
  margin-right: 4px;
}

.validateDigits__resend-timer {
  min-width: 40px;
}

.desktop-ver .validateDigits__container.screen {
  flex-direction: row;
}

.phone__wrapper {
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  display: flex !important;
}

.phone__wrapper .phone__countryCode {
  min-width: 72px;
  height: 38px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  font-size: small;
  position: relative;
  left: 5px;
}

.phone__wrapper.valid {
  border-color: #a1b383 !important;
}

.phone__wrapper.invalid {
  border-color: #f1aeb5 !important;
}

.phone__wrapper input {
  border: none;
  margin-bottom: 0;
}

.phone__selected-code {
  height: 38px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.phone__wrapper .open {
  border-radius: 10px 10px 0 0 !important;
}

.phone__wrapper .flag-icon {
  width: 20px;
  height: 20px;
}

.phone__wrapper .phone__countryCode--dropdown {
  width: 103%;
  height: 160px;
  z-index: 10;
  scrollbar-width: none;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 33px;
  left: -1px;
  overflow: auto;
}

.phone__wrapper .phone__countryCode--dropdown::-webkit-scrollbar {
  display: none;
}

.phone__wrapper .phone__countryCode--select {
  margin: 0;
  padding: 0 !important;
}

.phone__wrapper .phone__countryCode--option {
  place-content: space-around flex-start;
  align-items: center;
  padding: .5rem;
  list-style: none;
  display: flex;
}

.phone__wrapper .phone__countryCode--option:hover {
  background: #ededed;
}

.selected-country {
  background: #5996fb !important;
}

.providerScreen__wrapper {
  height: calc(100% - 166px);
  background: #f4f4f4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.providers__container .providers__list {
  max-height: calc(100% - 2rem);
  width: 100%;
  scrollbar-width: 0;
  padding: 2rem;
  overflow: auto;
}

.providers__container .providers__list::-webkit-scrollbar {
  display: none;
}

.providers__list--title {
  text-align: center;
  color: #000;
  opacity: .8;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
}

.providers__container .provider {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: .8rem;
  display: flex;
  box-shadow: 0 1px 5px 2px #0000001a;
}

.providers__container .provider img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.providers__container .provider i {
  border-radius: 50%;
  font-size: 30px;
}

.providers__container .provider i:before {
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.providers__container .provider .provider__option i:before {
  height: 35px;
  width: 35px;
}

.initial-image {
  min-width: 40px;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 22px;
  font-weight: 500;
  display: flex;
}

.providers__container .provider .provider__name {
  color: #777;
  margin-left: 10px;
  margin-right: 7px;
  font-family: Gotham Book, sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
}

.providers__container .provider .provider__title {
  color: #777;
  border-radius: 5px;
  margin-left: 10px;
  font-family: Gotham Book, sans-serif;
  font-size: 14px;
  font-weight: 200;
  display: inline-block;
}

.providers__container .provider .provider__option {
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: #fff;
  background: #f4f4f4;
  border-radius: 50%;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  font-size: 20px;
  display: flex;
}

.providers__container .provider .provider__option.is-selected {
  background: var(--primary-color);
}

.providerContinue__btn-wrapper {
  width: 40%;
  min-height: 3rem;
}

.providerContinue__btn {
  color: #fff;
  cursor: pointer;
  background: var(--primary-color);
  width: 100%;
  min-height: 3rem;
  border: 1px solid #0000;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: relative;
}

.providerContinue__btn.disabled {
  opacity: .5;
  pointer-events: none;
}

.provider__actions {
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

@media (hover: hover) {
  .providerContinue__btn:hover {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background: #fff;
  }
}

.provider__icon-check {
  font-size: 25px;
  font-weight: 600;
}

.providerContinue__btn .loading__wrapper {
  pointer-events: none;
  opacity: .5;
  width: calc(100% + 5px);
  box-shadow: none;
  pointer-events: none;
  z-index: 10;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -2px;
}

.providerContinue__btn .loading__wrapper.loading__widget {
  height: 100%;
  background: none;
  flex-direction: column;
  transition: right .4s;
  right: -100%;
}

.providerContinue__btn .loading__wrapper .loader {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  justify-content: center;
  display: flex;
}

.providerContinue__btn .loading__wrapper svg {
  width: 75px;
  height: 35px;
}

.providerContinue__btn .loading__wrapper svg rect {
  fill: #fff;
}

.providerContinue__btn .loading__iframe {
  opacity: 1 !important;
}

.screen__content.screen--providers:focus-visible {
  outline: none;
}

.loader__icon {
  height: 25px;
  width: 25px;
}

.loader__icon img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.providerContinue__btn.disabled--noneOpacity {
  pointer-events: none;
}

.provider-in-booking {
  z-index: 5;
}

.provider__info-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.provider__info-wrapper span {
  margin-bottom: 5px;
}

.desktop-ver .providers__container.screen {
  flex-direction: row;
}

.desktop-ver .providers__container.screen .providerScreen__wrapper {
  height: 100%;
}



/*# sourceMappingURL=index.70424f41.css.map */
