.phone__wrapper {
    position: relative;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    border: 1px solid lightgrey;
    margin-bottom: 15px;
}
.phone__wrapper .phone__countryCode {
    position: relative;
    left: 5px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    min-width: 72px;
    font-size: small;
    height: 38px;
}
.phone__wrapper.valid {
    border-color: #a1b383 !important;
}
.phone__wrapper.invalid {
    border-color: #f1aeb5 !important;
}
.phone__wrapper input {
    border: none;
    margin-bottom: 0;
}
.phone__selected-code {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 38px;
}
.phone__wrapper .open {
    border-radius: 10px 10px 0 0 !important;
}
.phone__wrapper .flag-icon {
    width: 20px;
    height: 20px;
}
.phone__wrapper .phone__countryCode--dropdown {
    position: absolute;
    border: 1px solid lightgrey;
    top: 33px;
    left: -1px;
    width: 103%;
    height: 160px;
    border-radius: 0 0 10px 10px;
    background: white;
    overflow: auto;
    z-index: 10;
    scrollbar-width: none;  /* Firefox */
}
.phone__wrapper .phone__countryCode--dropdown::-webkit-scrollbar {
    display: none;
}
.phone__wrapper .phone__countryCode--select {
    padding: 0 !important;
    margin: 0;
}
.phone__wrapper .phone__countryCode--option {
    list-style: none;
    padding: 0.5rem;
    display: flex;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}
.phone__wrapper .phone__countryCode--option:hover {
    background: #ededed;
}
.selected-country {
    background: #5996fb !important;
}
