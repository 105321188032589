.providerScreen__wrapper {
    height: calc(100% - 166px);    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
}

.providers__container .providers__list {
    max-height: calc(100% - 2rem);
    overflow: auto;
    padding: 2rem;
    width: 100%;
    scrollbar-width: 0;
}

.providers__container .providers__list::-webkit-scrollbar {
    display: none;
}

.providers__list--title {
    margin-bottom: 30px;
    text-align: center;
    color: #000;
    font-size: 20px;
    opacity: 0.8;
    font-weight: 400;
}
.providers__container .provider {
    margin-bottom: 1.5rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: white;
    box-shadow: 0 1px 5px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    cursor: pointer;
}
.providers__container .provider img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.providers__container .provider i {
    font-size: 30px;
    border-radius: 50%;
}

.providers__container .provider i::before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}

.providers__container .provider .provider__option i::before {
    height: 35px;
    width: 35px;
}

.initial-image {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
    background-color: var(--primary-color);
    font-weight: 500;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.providers__container .provider .provider__name {
    font-size: 16px;
    margin-right: 7px;
    color: #777;
    font-weight: 500!important;
    margin-left: 10px;
    font-family: Gotham Book,sans-serif;
}
.providers__container .provider .provider__title {
    font-size: 14px;
    display: inline-block;
    border-radius: 5px;
    font-weight: 200;
    color: #777;
    margin-left: 10px;
    font-family: Gotham Book,sans-serif;
}
.providers__container .provider .provider__option {
    margin-left: auto;
    background: #f4f4f4;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    font-size: 20px;
    color: white;
}
.providers__container .provider .provider__option.is-selected {
    background: var(--primary-color);
}

.providerContinue__btn-wrapper {
    width: 40%;
    min-height: 3rem;
}

.providerContinue__btn {
    border-radius: 10px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-color);
    border: 1px solid transparent;
    width: 100%;
    border-radius: 25px;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.providerContinue__btn.disabled{
    opacity: 0.5;
    pointer-events: none;
}
.provider__actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

@media (hover: hover) {
    .providerContinue__btn:hover{
        background: white;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
    }
}

.provider__icon-check {
    font-size: 25px;
    font-weight: 600;
}

.providerContinue__btn .loading__wrapper{
    pointer-events: none;
    opacity: 0.5;
    background-color: white;
    position: absolute;
    width: calc(100% + 5px);
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    height: 50px;
    border-radius: 25px;
    top: -2px;
}
.providerContinue__btn .loading__wrapper.loading__widget {
    right: -100%;
    transition: right .4s;
    height: 100%;
    background: transparent;
    flex-direction: column;
}
.providerContinue__btn .loading__wrapper .loader {
    height: 100%;
    display: flex;
    width: 100%;
    background: #fff;
    border-radius: 0 0 10px 0;
    border-radius: 25px;
    justify-content: center;
}
.providerContinue__btn .loading__wrapper svg {
    width: 75px;
    height: 35px;
}

.providerContinue__btn .loading__wrapper svg rect {
    fill: #fff;
}

.providerContinue__btn .loading__iframe {
    opacity: 1 !important;
}

.screen__content.screen--providers:focus-visible {
    outline: none;
}

.loader__icon {
    height: 25px;
    width: 25px;
}
.loader__icon img {
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.providerContinue__btn.disabled--noneOpacity{
    pointer-events: none;
}

.provider-in-booking {
    z-index: 5;
}

.provider__info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.provider__info-wrapper span {
    margin-bottom: 5px;
}

.desktop-ver .providers__container.screen {
    flex-direction: row;
}

.desktop-ver .providers__container.screen .providerScreen__wrapper {
    height: 100%;
}

