.toast__container.error {
    width: 90%;
    height: fit-content;
    z-index: 100;
    position: absolute;
    background: #da5049;
    color: #fff;
    padding: 2rem 4rem 2rem 2rem;
    top: 2rem;
    transform: translate3d(150%, 0, 0);
    border-radius: 9px;
    max-height: calc(100% - 200px);
    transition: transform 0.4s;
    cursor: pointer;
    font-size: 15px;
    line-break: auto;
}

.toast__container.error .error-msg {
    color: #fff;
    font-size: 15px;
    line-break: auto;
}

.toast__container.show {
    transform: translate3d(6%, 0, 0);
}

span.close-error {
    font-size: 27px;
    position: absolute;
    top: 29px;
    right: 20px;
    cursor: pointer;
}
